import { ResourceEntity } from '@backstage/catalog-model';
import { ScorecardEntity } from './scorecard';

export const AWS_ACCOUNT_RESOURCE_TYPE = 'aws-account';

export type AWSAccountResourceEntity = ResourceEntity & {
  spec: {
    type: typeof AWS_ACCOUNT_RESOURCE_TYPE
    id: string
    name: string
    nameAlias?: string
    initiative: string
    team: string
    project: string
    company: string
    pocSupport?: string
    emailSupport?: string
    slackSupport?: string
    slackOncall?: string
  }
};

export function getAccountResourceID(accountID: string): string {
  return `${AWS_ACCOUNT_RESOURCE_TYPE}-${accountID}`;
}

export const EKS_CLUSTER_RESOURCE_TYPE = 'eks-cluster';

export type EKSClusterResourceEntity = ResourceEntity & ScorecardEntity & {
  spec: {
    type: typeof EKS_CLUSTER_RESOURCE_TYPE
    awsRegion: string
    version: string
    versionEOL: string
    endpoint: string
    tags: Record<string, string>
  }
};

export function getClusterResourceID(clusterName: string, accountID: string): string {
  return `${EKS_CLUSTER_RESOURCE_TYPE}-${accountID}-${clusterName}`;
}
