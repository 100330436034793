import { Entity } from '@backstage/catalog-model';
import { get, has } from 'lodash';

export const CZI_ANNOTATION_SCORECARD = 'czi_eng_portal_scorecard';

export type ScorecardEntity = Entity & {
  metadata: {
    annotations: {
      [CZI_ANNOTATION_SCORECARD]: 'true'
    }
  }
  spec: {
    scorecard: {
      overallStatus: ScorecardStatus
      results: Record<string, ScorecardResult>
    }
  }
};

export enum ScorecardStatus {
  UNKNOWN = 0,
  CRITICAL,
  WARNING,
  SUCCESS,
}

export type ScorecardStatusStr = keyof typeof ScorecardStatus;

export type ScorecardResult = {
  status: ScorecardStatus
  score: number;
  message: string
};

export abstract class ScorecardCheck {
  abstract id(): string;
  abstract check(entity: Entity): ScorecardResult;
}

const SCORECARD_PATH = 'spec.scorecard';
const SCORECARD_STATUS_PATH = `${SCORECARD_PATH}.overallStatus`;

export const hasScorecard = (entity: Entity): boolean => has(entity, SCORECARD_PATH);

export async function hasScorecardAlert(
  entity: Entity,
): Promise<boolean> {
  return hasScorecard(entity) && get(entity, SCORECARD_STATUS_PATH) !== ScorecardStatus.SUCCESS;
}
