import React from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import { ScorecardEntity, ScorecardStatus } from '@eng-portal/czi-extensions-common';
import { Alert, Grid } from '@mui/material';

export function ScorecardAlertPanel(): JSX.Element {
  const { entity } = useEntity<ScorecardEntity>();

  return (
    <Grid container direction="row" spacing={2}>
      {Object.entries(entity.spec.scorecard?.results || {}).map(([key, result]) => {
        const severity = result.status === ScorecardStatus.CRITICAL ? 'error' : 'warning';
        return (
          <Grid item style={{ width: '100%' }} key={key}>
            <Alert severity={severity}>
              {result.message}
            </Alert>
          </Grid>
        );
      })}
    </Grid>
  );
}
