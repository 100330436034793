import React from 'react';
import {
  EmailIcon, InfoCard, Link, StructuredMetadataTable,
} from '@backstage/core-components';
import {
  Alert, Grid, List, ListItem, ListItemIcon, ListItemText, Tooltip,
} from '@mui/material';
import { useEntity } from '@backstage/plugin-catalog-react';
import { AWSAccountResourceEntity } from '@eng-portal/czi-extensions-common';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { omit } from 'lodash';
import { SlackLogoIcon } from '@eng-portal/czi-extensions-react';
import { CardTitle } from './CardTitle';

export function AWSAccountAboutCard(): JSX.Element {
  const { entity: account } = useEntity<AWSAccountResourceEntity>();
  if (!account) {
    return <Alert severity="error">Account not found</Alert>;
  }

  const {
    metadata: { name, title },
    spec,
  } = account;

  const { emailSupport } = spec;
  const emailSupportHref = emailSupport ? `mailto:${emailSupport}` : undefined;
  const slackMetadata: Record<string, string> = {};
  if (spec.slackSupport) {
    slackMetadata['Slack Support'] = `#${spec.slackSupport}`;
  }
  if (spec.slackOncall) {
    slackMetadata['Slack On-Call'] = spec.slackOncall;
  }

  const metadata: Record<string, any> = {
    ...omit(
      spec,
      'type',
      'owner',
      'emailSupport',
      'slackSupport',
      'slackOncall',
    ),
  };

  return (
    <InfoCard
      title={<CardTitle title={title || name} />}
    >
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item md={10} xl={11}>
          <List>
            <ListItem>
              <ListItemIcon>
                <Tooltip title="Metadata">
                  <AccountTreeIcon />
                </Tooltip>
              </ListItemIcon>
              <StructuredMetadataTable metadata={metadata} />
            </ListItem>
            <br />
            {(emailSupport && emailSupportHref) && (
              <div>
                <ListItem>
                  <ListItemIcon>
                    <Tooltip title="Email Support">
                      <EmailIcon />
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText>
                    <Link to={emailSupportHref}>{emailSupport}</Link>
                  </ListItemText>
                </ListItem>
                <br />
              </div>
            )}
            {Object.keys(slackMetadata).length > 0
              && (
              <ListItem>
                <ListItemIcon>
                  <Tooltip title="Slack Info">
                    <SlackLogoIcon />
                  </Tooltip>
                </ListItemIcon>
                <StructuredMetadataTable metadata={slackMetadata} />
              </ListItem>
              )}
          </List>
        </Grid>
      </Grid>
    </InfoCard>
  );
}
