import { SvgIcon } from '@mui/material';
import React from 'react';

export function SlackLogoSvg(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="mdi-slack" viewBox="0 0 24 24">
      <path
        // eslint-disable-next-line max-len
        d="M6,15A2,2 0 0,1 4,17A2,2 0 0,1 2,15A2,2 0 0,1 4,13H6V15M7,15A2,2 0 0,1 9,13A2,2 0 0,1 11,15V20A2,2 0 0,1 9,22A2,2 0 0,1 7,20V15M9,7A2,2 0 0,1 7,5A2,2 0 0,1 9,3A2,2 0 0,1 11,5V7H9M9,8A2,2 0 0,1 11,10A2,2 0 0,1 9,12H4A2,2 0 0,1 2,10A2,2 0 0,1 4,8H9M17,10A2,2 0 0,1 19,8A2,2 0 0,1 21,10A2,2 0 0,1 19,12H17V10M16,10A2,2 0 0,1 14,12A2,2 0 0,1 12,10V5A2,2 0 0,1 14,3A2,2 0 0,1 16,5V10M14,18A2,2 0 0,1 16,20A2,2 0 0,1 14,22A2,2 0 0,1 12,20V18H14M14,17A2,2 0 0,1 12,15A2,2 0 0,1 14,13H19A2,2 0 0,1 21,15A2,2 0 0,1 19,17H14Z"
      />
    </svg>
  );
}

export function SlackLogoIcon(): JSX.Element {
  return (
    <SvgIcon>
      <SlackLogoSvg />
    </SvgIcon>
  );
}
