import { ScorecardStatus } from '@eng-portal/czi-extensions-common';

export function getStatusColor(status: string): string {
  if (status === ScorecardStatus[ScorecardStatus.CRITICAL]) {
    return 'red';
  }
  if (status === ScorecardStatus[ScorecardStatus.WARNING]) {
    return 'orange';
  }
  if (status === ScorecardStatus[ScorecardStatus.SUCCESS]) {
    return 'green';
  }
  return 'white';
}
