import React from 'react';
import {
  InfoCard, StructuredMetadataTable,
} from '@backstage/core-components';
import {
  Alert, Grid, List, ListItem, ListItemIcon, Tooltip,
} from '@mui/material';
import { useEntity } from '@backstage/plugin-catalog-react';
import { EKSClusterResourceEntity } from '@eng-portal/czi-extensions-common';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { omit, pick } from 'lodash';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { CardTitle } from './CardTitle';

export function EKSClusterAboutCard(): JSX.Element {
  const { entity: cluster } = useEntity<EKSClusterResourceEntity>();
  if (!cluster) {
    return <Alert severity="error">Cluster not found</Alert>;
  }

  const {
    metadata: { name, title },
    spec,
  } = cluster;

  const { tags } = spec;
  const metadata: Record<string, any> = {
    ...omit(
      spec,
      'type',
      'owner',
      'dependsOn',
      'addons',
      'insights',
      'tags',
      'scorecard',
    ),
  };

  const filteredTags = pick(tags, ['project', 'env', 'service']);

  return (
    <InfoCard
      title={<CardTitle title={title || name} />}
    >
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item md={10} xl={11}>
          <List>
            <ListItem>
              <ListItemIcon>
                <Tooltip title="Metadata">
                  <AccountTreeIcon />
                </Tooltip>
              </ListItemIcon>
              <StructuredMetadataTable metadata={metadata} />
            </ListItem>
            <br />
            <ListItem>
              <ListItemIcon>
                <Tooltip title="Tags">
                  <LocalOfferIcon />
                </Tooltip>
              </ListItemIcon>
              <StructuredMetadataTable metadata={filteredTags} />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </InfoCard>
  );
}
