import React from 'react';
import { ScorecardEntity, ScorecardResult, ScorecardStatus } from '@eng-portal/czi-extensions-common';
import { Grid } from '@mui/material';
import { Table, TableColumn, TableFilter } from '@backstage/core-components';
import { cloneDeep } from 'lodash';
import { getStatusColor } from '../helper';

type ScorecardResultWithID = ScorecardResult & { id: string };

type EntityScorecardPanelProps = {
  entity: ScorecardEntity
  filterable?: boolean
  style?: any
};

EntityScorecardPanel.defaultProps = {
  filterable: true,
  style: {},
};

export function EntityScorecardPanel(props: EntityScorecardPanelProps): JSX.Element {
  const { entity, filterable, style } = props;

  const scorecardResults: ScorecardResultWithID[] = Object.entries(entity.spec.scorecard.results).map(([key, result]) => {
    const newResult = cloneDeep(result);
    newResult.status = ScorecardStatus[result.status] as any;
    return {
      id: key,
      ...newResult,
    };
  });

  const columns: TableColumn<ScorecardResultWithID>[] = [
    {
      title: 'Check ID',
      field: 'id',
      width: '20%',
    },
    {
      title: 'Check Status',
      field: 'status',
      width: '20%',
      render: (result: any): JSX.Element => {
        const { status } = result;
        return (
          <span style={{ color: getStatusColor(status) }}>
            {status}
          </span>
        );
      },
    },
    {
      title: 'Description',
      field: 'message',
    },
  ];

  const filters: TableFilter[] = [
    {
      column: 'Check Status',
      type: 'multiple-select',
    },
  ];

  return (
    <Grid item>
      <Table
        data={scorecardResults}
        columns={columns}
        filters={filters}
        style={{ padding: '10px', width: '100%', ...style }}
        options={{
          pageSize: scorecardResults.length,
          search: filterable,
          toolbar: filterable,
          paging: false,
        }}
      />
    </Grid>
  );
}
