/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { PropsWithChildren, useState } from 'react';
import HomeIcon from '@material-ui/icons/Home';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import { ViewList } from '@material-ui/icons';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Sidebar,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import InfoIcon from '@material-ui/icons/Info';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import useAsync from 'react-use/lib/useAsync';
import { configApiRef, identityApiRef, useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';
import { includesAdminGroup, PermissionConfig } from '@eng-portal/czi-extensions-common';
import KeyIcon from '@mui/icons-material/Key';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import { AssumedUserMenuItem } from './AssumedUser';
import { SidebarLogo } from './Logo';

export function SidebarMenu({ children }: PropsWithChildren<{}>): JSX.Element {
  const catalogApi = useApi(catalogApiRef);
  const config = useApi(configApiRef);
  const identityApi = useApi(identityApiRef);

  const [isAdminUser, setIsAdminUser] = useState(false);
  const [templates, setTemplates] = useState([] as Entity[]);
  const [currentUserGroups, setCurrentUserGroups] = useState<string[]>([]);

  useAsync(async (): Promise<void> => {
    const templateResp = await catalogApi.getEntities({
      filter: { kind: 'Template' },
    });
    setTemplates(templateResp.items);

    const userGroups = (await identityApi.getBackstageIdentity()).ownershipEntityRefs;
    setCurrentUserGroups(userGroups);
    setIsAdminUser(includesAdminGroup(userGroups, { config }));
  }, []);

  return (
    <SidebarPage>
      <Sidebar sidebarOptions={{ drawerWidthOpen: 235 }}>
        <SidebarLogo />
        <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
          <SidebarSearchModal />
        </SidebarGroup>
        <SidebarDivider />
        <SidebarGroup label="Menu" icon={<MenuIcon />}>
          <SidebarItem icon={HomeIcon} to="/" text="Home" />
          { templates.length > 0 && <SidebarItem icon={CreateComponentIcon} to="create" text="Automations" /> }
          <SidebarItem icon={ViewList} to="catalog" text="Catalog" />

          { PermissionConfig.keyManagementRootView.canAccess(currentUserGroups, { config })
              && <SidebarItem icon={KeyIcon as any} to="aws-key-management" text="Key Management" /> }

          {/* need to specify width for Privilege Escalation item because the default cuts off the text even though it can fit */}
          <SidebarItem icon={AutoGraphIcon as any} to="privilege-escalation" text="Privilege Escalation" style={{ width: 245 }} />

          { isAdminUser
              && <SidebarItem icon={SportsScoreIcon} to="scorecards" text="Scorecards" /> }

        </SidebarGroup>
        <SidebarSpace />
        <SidebarGroup>
          <AssumedUserMenuItem />
        </SidebarGroup>
        <SidebarDivider />
        <SidebarItem icon={InfoIcon} to="help" text="Help Center" />
        <SidebarGroup
          label="Settings"
          icon={<UserSettingsSignInAvatar />}
          to="/settings"
        >
          <SidebarSettings />
        </SidebarGroup>
      </Sidebar>
      {children}
    </SidebarPage>
  );
}
