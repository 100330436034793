import React from 'react';
import { Content, ContentHeader, Page } from '@backstage/core-components';
import { useEntity } from '@backstage/plugin-catalog-react';
import { ScorecardEntity } from '@eng-portal/czi-extensions-common';
import { EntityScorecardPanel } from '../EntityScorecardPanel';

export function ScorecardEntityTab(): JSX.Element {
  const { entity } = useEntity<ScorecardEntity>();

  return (
    <Page themeId="tool">
      <Content noPadding>
        <ContentHeader title="Scorecard" />
        <EntityScorecardPanel entity={entity} />
      </Content>
    </Page>
  );
}
