import * as React from 'react';
import { Route } from 'react-router';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import { CatalogEntityPage, CatalogIndexPage, catalogPlugin } from '@backstage/plugin-catalog';
import { CatalogImportPage, catalogImportPlugin } from '@backstage/plugin-catalog-import';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { ScaffolderFieldExtensions } from '@backstage/plugin-scaffolder-react';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage-community/plugin-tech-radar';
import {
  DefaultTechDocsHome, TechDocsIndexPage, techdocsPlugin, TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import {
  AlertDisplay, OAuthRequestDialog, SignInProviderConfig, SignInPage,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { oktaAuthApiRef, discoveryApiRef, useApi } from '@backstage/core-plugin-api';
import { HappyPage } from '@eng-portal/happy';
import type { BackstageUserIdentity, IdentityApi, ProfileInfo } from '@backstage/core-plugin-api';
import * as Sentry from '@sentry/react';
import { LocalStorageCache } from '@eng-portal/czi-extensions-react';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { KeyManagementPage } from '@eng-portal/aws-key-management';
import { PrivilegeEscalationPage } from '@eng-portal/privilege-escalation';
import { PermissionConfig } from '@eng-portal/czi-extensions-common';
import { ScorecardsPage } from '@eng-portal/scorecards';
import { AmazonProfilePickerFieldExtension } from './scaffolder/AmazonProfilePicker';
import { SidebarMenu } from './components/Root';
import { searchPage } from './components/search/SearchPage';
import { entityPage } from './components/catalog/EntityPage';
import { apis } from './apis';
import { HiddenRepoUrlPickerExtension } from './scaffolder/HiddenRepoUrlPicker';
import { HomePage } from './components/home/HomePage';
import { HappyDirectoryPathSpecifierExtension } from './scaffolder/HappyDirectoryPathSpecifier';
import { OktaAppPickerFieldExtension } from './scaffolder/OktaAppPicker';
import { setTokenCookie } from './cookieAuth';
import { HelpCenterPage } from './components/help/HelpCenterPage';

const oktaProvider: SignInProviderConfig = {
  id: 'okta-auth-provider',
  title: 'okta',
  message: 'Sign in using Okta',
  apiRef: oktaAuthApiRef,
};
const app = createApp({
  apis,
  components: {
    SignInPage: (props) => {
      const { onSignInSuccess } = props;
      const discoveryApi = useApi(discoveryApiRef);
      return (
        <SignInPage
          {...props}
          auto
          provider={oktaProvider}
          onSignInSuccess={async (identityApi: IdentityApi): Promise<void> => {
            // create a wrapper so we can clear the localstorage cache when a user signs out
            const identityApiWrapper = {
              getProfileInfo(): Promise<ProfileInfo> {
                return identityApi.getProfileInfo();
              },
              getBackstageIdentity(): Promise<BackstageUserIdentity> {
                return identityApi.getBackstageIdentity();
              },
              getCredentials(): Promise<{ token?: string }> {
                return identityApi.getCredentials();
              },
              async signOut(): Promise<void> {
                LocalStorageCache.clear();
                await identityApi.signOut();
              },
            };
            setTokenCookie(
              await discoveryApi.getBaseUrl('cookie'),
              identityApiWrapper,
            );
            Sentry.setUser({ username: (await identityApiWrapper.getBackstageIdentity()).userEntityRef });
            onSignInSuccess(identityApiWrapper);
          }}
        />
      );
    },
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  featureFlags: [],
});

const AppProvider = app.getProvider();
const AppRouter = app.getRouter();

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route path="/catalog" element={<CatalogIndexPage initialKind="Group" />} />
    <Route path="/catalog/:namespace/:kind/:name" element={<CatalogEntityPage />}>
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />}>
      <DefaultTechDocsHome />
    </Route>
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route path="/help" element={<HelpCenterPage />} />
    <Route
      path="/create"
      element={(
        <ScaffolderPage
          headerOptions={{
            title: 'Automations',
            subtitle: 'Workflow automations to help you accomplish more with less effort',
          }}
          groups={[
            {
              title: 'Happy Path',
              filter: (entity): boolean => entity?.metadata?.tags?.includes('happy-path') ?? false,
            },
            {
              title: 'Core Routine',
              filter: (entity): boolean => entity?.metadata?.tags?.includes('core-routine') ?? false,
            },
          ]}
        />
    )}
    >
      <ScaffolderFieldExtensions>
        <AmazonProfilePickerFieldExtension />
        <HiddenRepoUrlPickerExtension />
        <HappyDirectoryPathSpecifierExtension />
        <OktaAppPickerFieldExtension />
      </ScaffolderFieldExtensions>
    </Route>
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route path="/tech-radar" element={<TechRadarPage width={1500} height={800} />} />
    <Route
      path="/catalog-import"
      element={(
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      )}
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/happy" element={<HappyPage />} />
    <Route
      path="/aws-key-management"
      element={(
        <RequirePermission permission={PermissionConfig.keyManagementRootView.permission}>
          <KeyManagementPage />
        </RequirePermission>
      )}
    />
    <Route path="/privilege-escalation" element={<PrivilegeEscalationPage />} />
    <Route path="/scorecards" element={<ScorecardsPage />} />
  </FlatRoutes>
);

function App(): JSX.Element {
  return (
    <AppProvider>
      <AlertDisplay />
      <OAuthRequestDialog />
      <AppRouter>
        <SidebarMenu>{routes}</SidebarMenu>
      </AppRouter>
    </AppProvider>
  );
}

export default App;
